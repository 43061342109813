import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  ShopManagementDto,
  CreateOrUpdateShopManagementDto,
  GetListShopManagementDto
} from '@/framework/models/ShopManagementDto'
import {Guid} from 'guid-typescript'

export default class ShopManagementApi extends CrudAppService <ShopManagementDto, ShopManagementDto, Guid,
  GetListShopManagementDto, CreateOrUpdateShopManagementDto, CreateOrUpdateShopManagementDto> {
  constructor() {
    super('/keep-fit/shop-management');
  }
}
