import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface ShopManagementDto extends ExtensibleEntityDto <Guid> {
  name: string
  phone: string
  district: string
  address: string
  remark: string
  fileUrl: string
  state: number
}

export class CreateOrUpdateShopManagementDto {
  public name: string = ''
  public phone: string = ''
  public district: string = ''
  public address: string = ''
  public remark: string = ''
  public fileUrl: string = ''
  public state?: number
}

export class GetListShopManagementDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
